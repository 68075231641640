//      
var hasOwn = function hasOwn(object, key) {
  return Object.prototype.hasOwnProperty.call(object, key);
};

function deepEquals(equals, deepObjects) {
  function deep(valueA, valueB) {
    if (equals(valueA, valueB)) {
      return true;
    }

    if (Array.isArray(valueA)) {
      if (!Array.isArray(valueB) || valueA.length !== valueB.length) {
        return false;
      } // Check deep equality of each value in A against the same indexed value in B


      if (!valueA.every(function (value, index) {
        return deep(value, valueB[index]);
      })) {
        return false;
      } // could not find unequal items


      return true;
    }

    if (Array.isArray(valueB)) {
      return false;
    }

    if (typeof valueA === "object") {
      if (typeof valueB !== "object") {
        return false;
      }

      var isANull = valueA === null;
      var isBNull = valueB === null;

      if (isANull || isBNull) {
        return isANull === isBNull;
      }

      var aKeys = Object.keys(valueA);
      var bKeys = Object.keys(valueB);

      if (aKeys.length !== bKeys.length) {
        return false;
      } // Should we compare with shallow equivalence or deep equivalence?


      var equalityChecker = deepObjects ? deep : equals; // Check if objects share same keys, and each of those keys are equal

      if (!aKeys.every(function (aKey) {
        return hasOwn(valueA, aKey) && hasOwn(valueB, aKey) && equalityChecker(valueA[aKey], valueB[aKey]);
      })) {
        return false;
      } // could not find unequal keys or values


      return true;
    }

    return false;
  }

  return deep;
}

//      
var findIndex = function findIndex(arr, fn) {
  for (var i = 0; i < arr.length; i++) {
    if (fn(arr[i])) {
      return i;
    }
  }

  return -1;
};

function lruCache(limit, equals) {
  var entries = [];

  function get(key) {
    var cacheIndex = findIndex(entries, function (entry) {
      return equals(key, entry.key);
    }); // We found a cached entry

    if (cacheIndex > -1) {
      var entry = entries[cacheIndex]; // Cached entry not at top of cache, move it to the top

      if (cacheIndex > 0) {
        entries.splice(cacheIndex, 1);
        entries.unshift(entry);
      }

      return entry.value;
    } // No entry found in cache, return null


    return undefined;
  }

  function put(key, value) {
    if (!get(key)) {
      entries.unshift({
        key: key,
        value: value
      });

      if (entries.length > limit) {
        entries.pop();
      }
    }
  }

  return {
    get: get,
    put: put
  };
}

//      
function singletonCache(equals) {
  var entry;
  return {
    get: function get(key) {
      if (entry && equals(key, entry.key)) {
        return entry.value;
      }
    },
    put: function put(key, value) {
      entry = {
        key: key,
        value: value
      };
    }
  };
}

//      

function createCache(limit, equals) {
  return limit === 1 ? singletonCache(equals) : lruCache(limit, equals);
}

function createEqualsFn(basicEquals, deepObjects) {
  // Choose strategy for basic or deep object equals
  var equals = deepObjects ? deepEquals(basicEquals, deepObjects) : basicEquals;
  return function (valueA, valueB) {
    // The arguments are always the argument array-like objects
    // Different lengths means they are not the same
    if (valueA.length !== valueB.length) {
      return false;
    } // Compare the values


    for (var index = 0; index < valueA.length; index += 1) {
      if (!equals(valueA[index], valueB[index])) {
        return false;
      }
    } // Found no conflicts


    return true;
  };
}

function memoize() {
  var limit = 1;

  var equals = function equals(valueA, valueB) {
    return valueA === valueB;
  };

  var deepObjects = false;

  for (var _len = arguments.length, config = new Array(_len), _key = 0; _key < _len; _key++) {
    config[_key] = arguments[_key];
  }

  if (typeof config[0] === "number") {
    limit = config.shift();
  }

  if (typeof config[0] === "function") {
    equals = config.shift();
  } else if (typeof config[0] === "undefined") {
    // Support passing undefined equal argument;
    config.shift();
  }

  if (typeof config[0] === "boolean") {
    deepObjects = config[0];
  }

  var cache = createCache(limit, createEqualsFn(equals, deepObjects));
  return function (fn) {
    return function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      var value = cache.get(args);

      if (value === undefined) {
        value = fn.apply(fn, args);
        cache.put(args, value);
      }

      return value;
    };
  };
}

export default memoize;
